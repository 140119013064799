import { useMatch } from '@reach/router';
import type { FocusEventHandler } from 'react';
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useIntl } from 'react-intl';

import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useExperiment } from '@xing-com/hub';

import { useAnimatedPlaceholder } from '../use-animated-placeholder';

import * as S from './input.styles';

const QUERY_MAX_LENGTH = 1000;

type Props = {
  autofocus?: boolean;
  autofocusOnLoad?: boolean;
  disableDefaultAnimation?: boolean;
  /**
   * Set this prop to true when a suggestions animation is running. The following will happen
   * to the component
   * - The `truncatedQuery` will be updated on every `defaultValue` changes
   * - The ellipsis logic will not be triggered, so that the input still grows
   */
  isAnimating?: boolean;
  defaultValue?: string;
  nested?: boolean;
  onChangeKeywords: (value: string) => void;
  onEnterPress: () => void;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
};

export const SearchBarInput = forwardRef<HTMLTextAreaElement | null, Props>(
  (props, forwardedRef) => {
    const {
      autofocus,
      autofocusOnLoad,
      defaultValue,
      disableDefaultAnimation,
      isAnimating,
      nested,
      onBlur,
      onChangeKeywords,
      onEnterPress,
      onFocus,
      ...restProps
    } = props;
    const { formatMessage } = useIntl();
    const isMREnabled = useExperiment('ABACUS-484') === 'B';
    const isAILanding = !!useMatch(ROUTES.searchAiLanding);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const runPlaceholderAnimation = useAnimatedPlaceholder(inputRef, {
      disable: disableDefaultAnimation,
    });
    const [truncatedQuery, setTruncatedQuery] = useState(defaultValue);

    useImperativeHandle(
      forwardedRef,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      () => inputRef.current as HTMLTextAreaElement
    );

    useEffect(() => {
      if (!defaultValue) {
        runPlaceholderAnimation();
      } else {
        if (truncatedQuery !== defaultValue && truncatedQuery === '') {
          setTruncatedQuery(defaultValue);
        }
        // we force a blur to trigger the ellipsis logic
        inputRef.current?.focus();
        inputRef.current?.blur();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isAnimating && defaultValue) {
        setTruncatedQuery(defaultValue);
        // Check if the input is focused
        if (document.activeElement !== inputRef.current && inputRef.current) {
          // If not, focus
          inputRef.current.focus();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    useEffect(() => {
      if (typeof forwardedRef === 'function') {
        forwardedRef(inputRef.current);
      } else if (forwardedRef) {
        forwardedRef.current = inputRef.current;
      }
    }, [forwardedRef]);

    const handleOnChangeKeywords = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
      const value = e.target.value;
      onChangeKeywords(value.trim());
      setTruncatedQuery(value);

      if (!value) {
        runPlaceholderAnimation();
      }
    };

    const handleOnInputKeyDown = (
      e: React.KeyboardEvent<HTMLTextAreaElement>
    ): void => {
      if (e.key === 'Enter') {
        e.preventDefault();

        onEnterPress();
      }
    };

    const handleOnBlurKeywords: FocusEventHandler<HTMLTextAreaElement> = (
      e
    ): void => {
      // The blur will also take place when the submit button is clicked, in which case
      // we don't want to blur anything
      if (
        e.relatedTarget instanceof HTMLButtonElement &&
        e.relatedTarget.type === 'submit'
      ) {
        e.relatedTarget.dispatchEvent(new Event('click', { bubbles: true }));
        return;
      }

      // Do not allow blur if animation is running
      if (isAnimating) {
        return;
      }

      // scroll to top of textarea text
      e.target.scrollTo(0, 0);

      // When unfocusing the query, the textarea will shrink to the original
      // height (3 lines). We want to show an ellipsis if the text is longer than
      // that. We can manipulate the value directly without React, so we don't
      // lose the original text (in a state). We keep removing content until the
      // scrollHeight is the same as the original height. The loop has a limit of
      // iterations to avoid infinite loops (Shouldn't happen, but just in case)
      for (
        let i = 0;
        i < QUERY_MAX_LENGTH && e.target.scrollHeight > e.target.clientHeight;
        ++i
      ) {
        e.target.value = e.target.value.slice(0, -4) + '...';
      }
      setTruncatedQuery(e.target.value);
      onBlur?.(e);
    };

    const handleOnFocusKeywords: FocusEventHandler<HTMLTextAreaElement> = (
      e
    ) => {
      // we restore the original text
      setTruncatedQuery(defaultValue);
      onFocus?.(e);
    };

    useEffect(() => {
      if (!autofocus || !inputRef.current) {
        return;
      }

      const length = inputRef.current.value.length;
      if (!length) {
        return;
      }

      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }, [autofocus, inputRef]);

    useEffect(() => {
      if (autofocusOnLoad && inputRef.current) {
        inputRef.current.focus();
      }
    }, [autofocusOnLoad]);

    return (
      // @ts-expect-error FIXME: SC6
      <S.Input
        value={truncatedQuery}
        onChange={handleOnChangeKeywords}
        onKeyDown={handleOnInputKeyDown}
        maxLength={QUERY_MAX_LENGTH}
        onBlur={handleOnBlurKeywords}
        onFocus={handleOnFocusKeywords}
        ref={inputRef}
        data-testid="cs-search-bar-textarea"
        {...restProps}
        variant="plain"
        sizeConfined="xlarge"
        multiline
        $nested={nested}
        $isAnimating={isAnimating}
        aria-label={formatMessage({ id: 'JOBS_SEARCH_AI_INPUT_PLACEHOLDER_1' })}
        $isLanding={isAILanding && isMREnabled}
      />
    );
  }
);
